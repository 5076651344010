export const emailTest = /^(([^<>()[\]\\.,:\s@']+(\.[^<>()[\]\\.,:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const passwordTest = /^(?=.*\d)(?=.*[a-zA-Z])([a-zA-Z0-9?!@_#$%^&*`~<>|:;'"+}{[\]]{6,})$/
export const passwordTestOld = /^(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/
export const numberTest = /^\d+$/
export const TEL = /^[+]?[0-9]{10,20}$/
export const TIME = /^[+]?[0-9]{2}:[0-9]{2}$/
export const TEL2 = /^[+]?[0-9]{2}[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im
export const nameTest = /^[_a-zA-Zа-яА-ЯёЁіІ'їЇєЄ0-9]*((-|\s)*[_a-zA-Zа-яА-ЯёЁіІ'їЇєЄ0-9])*$/
export const fullName = '/^([a-zA-Z-\' ]+)$/'
export const countTest = /^\d+$/
export const FORMAT_DATE_TEXT = 'DD MMMM YYYY'
export const FORMAT_DATE_AND_TIME = 'DD/MM/YYYY HH:mm:ss'
export const FORMAT_DATE = 'DD.MM.YYYY'
export const FORMAT_DATE_S = 'YYYY-DD-MM'
export const FORMAT_DATE_LOG = 'DD.MM.YYYY'
export const FORMAT_TIME = 'HH:mm'
export const DATE = 'YYYY-MM-DD HH:mm:ss'
export const ONLY_DATE = 'YYYY-MM-DD'
export const DATE_START = 'YYYY-MM-DD 00:00:00'
export const DATE_END = 'YYYY-MM-DD 23:59:59'
